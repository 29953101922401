import type * as Types from '../../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetMachinesQueryVariables = Types.Exact<{
  uuidOrganization: Types.Scalars['String']['input'];
}>;


export type GetMachinesQuery = { __typename?: 'Query', machines?: Array<{ __typename?: 'Machine', uuid: string, ids?: any | null, verificationStatus?: Types.EnumMachineVerificationStatus | null, description?: string | null, model: string, reg?: string | null, year?: number | null, type: string, status?: Types.EnumMachineStatus | null, availabilityStatus?: { __typename?: 'MachineAvailabilityStatus', status?: Types.EnumMachineAvailabilityStatusStatus | null, statusEnds?: any | null } | null, date?: { __typename?: 'MachineDate', created: number, updated?: number | null, available?: number | null } | null, photos: Array<{ __typename?: 'MachinePhotos', type: string, src: string }>, prices: Array<{ __typename?: 'MachinePrices', type: string, value: number }>, typeData: { __typename?: 'MachineType', uuid: string, ids?: any | null, unit?: Array<string | null> | null, name: string, additional?: any | null, enabled?: boolean | null, date?: { __typename?: 'MachineTypeDate', created: number, updated?: number | null } | null, params: Array<{ __typename?: 'MachineTypeParams', uuid: string, ids?: any | null, name: string, type: Types.EnumMachineTypeParamsType, settings?: any | null, unit?: string | null, isEquipment?: boolean | null, additional?: any | null, isRequired?: boolean | null, enabled?: boolean | null, paramPurpose?: { __typename?: 'MachineTypeParamsParamPurpose', usual?: boolean | null, main?: boolean | null, entry?: boolean | null } | null } | null> }, params: Array<{ __typename?: 'MachineParams', refUuid: string, value?: any | null }>, documents?: Array<{ __typename?: 'MachineDocuments', url?: string | null, type?: string | null } | null> | null } | null> | null };

export const GetMachinesDocument = gql`
    query GetMachines($uuidOrganization: String!) {
  machines(uuidOrganization: $uuidOrganization) {
    uuid
    ids
    verificationStatus
    availabilityStatus {
      status
      statusEnds
    }
    date {
      created
      updated
      available
    }
    description
    photos {
      type
      src
    }
    prices {
      type
      value
    }
    model
    reg
    year
    type
    typeData {
      uuid
      date {
        created
        updated
      }
      ids
      unit
      name
      params {
        uuid
        ids
        name
        type
        settings
        unit
        isEquipment
        paramPurpose {
          usual
          main
          entry
        }
        additional
        isRequired
        enabled
      }
      additional
      enabled
    }
    params {
      refUuid
      value
    }
    documents {
      url
      type
    }
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMachinesQueryGql extends Apollo.Query<GetMachinesQuery, GetMachinesQueryVariables> {
    override document = GetMachinesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }