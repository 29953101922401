import type * as Types from '../../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntryCancelProposeMutationVariables = Types.Exact<{
  proposeRef: Types.Scalars['String']['input'];
}>;


export type EntryCancelProposeMutation = { __typename?: 'Mutation', entryCancelPropose: { __typename?: 'Entry', id: string } };

export const EntryCancelProposeDocument = gql`
    mutation entryCancelPropose($proposeRef: String!) {
  entryCancelPropose(proposeRef: $proposeRef) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryCancelProposeMutationGql extends Apollo.Mutation<EntryCancelProposeMutation, EntryCancelProposeMutationVariables> {
    override document = EntryCancelProposeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }