import type * as Types from '../../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntryInitDepartureAgreementMutationVariables = Types.Exact<{
  proposeRef: Types.Scalars['ID']['input'];
  date: Types.Scalars['String']['input'];
  comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type EntryInitDepartureAgreementMutation = { __typename?: 'Mutation', entryInitDepartureAgreement?: { __typename?: 'EntryInitDepartureAgreementResultType', success?: boolean | null } | null };

export const EntryInitDepartureAgreementDocument = gql`
    mutation entryInitDepartureAgreement($proposeRef: ID!, $date: String!, $comment: String) {
  entryInitDepartureAgreement(
    proposeRef: $proposeRef
    date: $date
    comment: $comment
  ) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryInitDepartureAgreementMutationGql extends Apollo.Mutation<EntryInitDepartureAgreementMutation, EntryInitDepartureAgreementMutationVariables> {
    override document = EntryInitDepartureAgreementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }